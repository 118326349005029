import React from 'react'
import './Skills.css'
import {pLanguages} from '../../data/skillsData'
import {frameworks} from '../../data/skillsData'
import {databases} from '../../data/skillsData'
import {ide} from '../../data/skillsData'
import {otherstechnologies} from '../../data/skillsData'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const Skills = () => {

  return (
    <div className='skills-container' id='skills'>
      <div className='skills-heading'>
        My Skills
        </div>
        <div className='skills-container'>
        <div className='skills-heading2'>
        Programming Languages
        </div>
      <div className='skill-content'>
     {pLanguages.map((skill)=>(
     <Card sx={{ maxWidth: 345 }} className="skill-slide">
      <CardContent className='skill-card-content'>
        <Typography gutterBottom variant="h5" component="div">
          {skill.name}
        </Typography>
      </CardContent>
    </Card> 
    ))}
    </div>
    </div>
    <div className='skills-container'>
        <div className='skills-heading2'>
        Frameworks
        </div>
      <div className='skill-content'>
     {frameworks.map((skill, i)=>(
     <Card sx={{ maxWidth: 345 }} className="skill-slide">
      <CardContent className='skill-card-content'>
        <Typography gutterBottom variant="h5" component="div">
          {skill.name}
        </Typography>
      </CardContent>
    </Card> 
    ))}
    </div>
    </div>
    <div className='skills-container'>
        <div className='skills-heading2'>
        IDE
        </div>
      <div className='skill-content'>
     {ide.map((skill, i)=>(
     <Card sx={{ maxWidth: 345 }} className="skill-slide">
      <CardContent className='skill-card-content'>
        <Typography gutterBottom variant="h5" component="div">
          {skill.name}
        </Typography>
      </CardContent>
    </Card> 
    ))}
    </div>
    </div>
    <div className='skills-container'>
        <div className='skills-heading2'>
        Databases
        </div>
      <div className='skill-content'>
     {databases.map((skill, i)=>(
     <Card sx={{ maxWidth: 345 }} className="skill-slide">
      <CardContent className='skill-card-content'>
        <Typography gutterBottom variant="h5" component="div">
          {skill.name}
        </Typography>
      </CardContent>
    </Card> 
    ))}
    </div>
    </div>
    <div className='skills-container'>
        <div className='skills-heading2'>
        Others
        </div>
      <div className='skill-content'>
     {otherstechnologies.map((skill, i)=>(
     <Card sx={{ maxWidth: 345 }} className="skill-slide">
      <CardContent className='skill-card-content'>
        <Typography gutterBottom variant="h5" component="div">
          {skill.name}
        </Typography>
      </CardContent>
    </Card> 
    ))}
    </div>
    </div>   
    </div>
    
  )
}

export default Skills