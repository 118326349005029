export const pLanguages = [
    {
  
      name:'Java',
      image: 'FRANKLIN'
    },
    {
  
      name:'JavaScript',
      image: 'FRANKLIN'
    }
  ];

  export const frameworks = [
    {
  
      name: 'React Js',
      image: 'JOHN KEVIN'
    },
    {
  
      name:'Node Js',
      image: 'FRANKLIN'
    },
    {
  
      name:'Spring Boot',
      image: 'FRANKLIN'
    }
  ];

  export const ide = [
    {
  
      name:
        "VS Code",
      image: 'MATHEW HENDRICKSON'
    },
    {
  
      name:'Android Studio',
      image: 'FRANKLIN'
    },
    {
  
      name:'Visual Studio',
      image: 'FRANKLIN'
    },
    {
  
      name:'Eclipse',
      image: 'FRANKLIN'
    },
    {
  
      name:'Visual Studio',
      image: 'FRANKLIN'
    }
  ];

  export const databases = [
    {
  
      name:
        "MySQL",
      image: 'MATHEW HENDRICKSON'
    },
    {
  
      name:'Navicate',
      image: 'FRANKLIN'
    },
    {
  
      name:'Mongo DB',
      image: 'FRANKLIN'
    },
    {
  
      name:'Firebase',
      image: 'FRANKLIN'
    }
  ];

  export const otherstechnologies = [
    {
  
      name:
        "Github",
      image: 'MATHEW HENDRICKSON'
    },
    {
  
      name:
        "GitLab",
      image: 'MATHEW HENDRICKSON'
    },
    {
  
      name:'Sublime Text',
      image: 'FRANKLIN'
    },
    {
  
      name:'Jira',
      image: 'FRANKLIN'
    }
  ];
  