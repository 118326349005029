// import Box from '@mui/material/Box';
// import TextField from '@mui/material/TextField';
import React from 'react';
import './Contact.css';
// import emailjs from '@emailjs/browser';
// import Button from '@mui/material/Button';
// import SendIcon from '@mui/icons-material/Email';

// function RedBar() {
//   return (
//     <Box
//       sx={{
//         height: 20,
//         backgroundColor: (theme) =>
//           theme.palette.mode === 'light'
//             ? 'rgba(255, 0, 0, 0.1)'
//             : 'rgb(255 132 132 / 25%)',
//       }}
//     />
//   );
// }

export default function Contact() {
  // const form = useRef();

  // const sendEmail = (e) => {
  //   e.preventDefault();
  
  //   emailjs
  //     .sendForm('service_g76obnq', 'template_dsiqdpb', form.current, 'VBCaLk65qRAfa13BV')
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //         alert('Message sent successfully!');
  //         window.location.reload(); // Reload the page
  //       },
  //       (error) => {
  //         console.log(error.text);
  //         // alert('Failed to send email. Please try again.');
  //       }
  //     );
  // };  

  return (
    <div className="contact-container" id="contact">
      <div className="contact-heading">My Contacts</div>
      <div className="contact-right">
        <a href="https://www.facebook.com/pasindu.manjitha.5" target="_blank" rel="noopener noreferrer" className="contact-icon">
          <i className="fab fa-facebook-f" style={{color:"goldenrod", marginRight:"10px"}}></i>
        </a>
        <a href="https://www.instagram.com/pasindu._perera._/" target="_blank" rel="noopener noreferrer" className="contact-icon">
          <i className="fab fa-instagram" style={{color:"goldenrod", marginRight:"10px"}}></i>
        </a>
        <a href="https://www.linkedin.com/in/pasindu-perera-120752221" target="_blank" rel="noopener noreferrer" className="contact-icon">
          <i className="fab fa-linkedin-in" style={{color:"goldenrod", marginRight:"10px"}}></i>
        </a>
        <a href="https://twitter.com/PasinduPerera99" target="_blank" rel="noopener noreferrer" className="contact-icon">
          <i className="fab fa-twitter" style={{color:"goldenrod", marginRight:"10px"}}></i>
        </a>
        <a href="https://medium.com/@pasinduperera10" target="_blank" rel="noopener noreferrer" className="contact-icon">
          <i className="fab fa-medium-m" style={{color:"goldenrod", marginRight:"10px"}}></i>
        </a>
        <a href="https://github.com/PasinduPerera10" target="_blank" rel="noopener noreferrer" className="contact-icon">
          <i className="fab fa-github" style={{color:"goldenrod", marginRight:"10px"}}></i>
        </a>
        {/* <a href="https://stackexchange.com/users/27569271/pasindu-perera" target="_blank" rel="noopener noreferrer" className="contact-icon">
          <i className="fab fa-stack-overflow" style={{color:"goldenrod", marginRight:"10px"}}></i>
        </a> */}
      </div>
      {/* <div style={{ color: 'white', textAlign: 'center', margin: '30px', fontSize: '25px' }}>Or</div>
      <div className="contact-form">
        <form ref={form} className="contact-message" onSubmit={sendEmail}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              '& .MuiTextField-root': { width: '25ch' },
              textAlign: 'center',
            }}
          >
            <RedBar />
            <TextField style={{ width: '400px' }} className="contact-textfield" type="text" name="from_name" label={'Name'} id="margin-none" required />
            <RedBar />
            <TextField style={{ width: '400px' }} className="contact-textfield" type="email" name="user_email" label={'Email'} id="margin-dense" margin="dense" required />
            <RedBar />
            <TextField style={{ width: '400px' }} className="contact-textfield" type="text" name="message" label={'Message'} id="margin-normal" margin="normal" required />
            <RedBar />
            <Button style={{ background: 'goldenrod' }} className="contact-btn" variant="contained" type="submit" endIcon={<SendIcon />}>
              Send
            </Button>
          </Box>
        </form>
      </div> */}
    </div>
  );
}
