import React from 'react'
import './Career.css'
import {careerData} from '../../data/careerData'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const Career = () => {

  return (
    <div className='careers-container' id='careers'>
      <div className='careers-heading'>
        My Career
        </div>
      <div className='career-content'>
     {careerData.map((career, i)=>(
     <Card sx={{ maxWidth: 345 }} className="career-slide">
      <CardContent className='career-card-content'>
        <Typography gutterBottom variant="h5" component="div">
          {career.company}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {career.position}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {career.duration}
        </Typography>
      </CardContent>
    </Card> 
    ))}
    </div>  
    </div>
    
  )
}

export default Career