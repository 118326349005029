import React from 'react'
import './Projects.css'
import {projectsData, projectsData1} from '../../data/projectsData'
import {personalprojectsData} from '../../data/projectsData'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const Projects = () => {

  return (
    <div className='projects-container' id='projects'>
      <div className='projects-heading'>
        My Projects
        </div>
        <div>
        <div className='projects-heading2'>
        My Academic Projects
        </div>
      <div className='project-content'>
     {projectsData.map((project, i)=>(
     <Card sx={{  }} className="project-slide">
      <CardContent className='project-card-content'>
        <Typography gutterBottom variant="h5" component="div">
          {project.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {project.technologies}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {project.year}
        </Typography>
                {/* <a href={project.githubUrl} style={{textDecoration:"none"}}>
                  <Typography variant="body2" color="text.secondary" style={{textAlign:"center", color:"white"}}>
                  GitHub URL
                </Typography>
                </a>
                <a href={project.webUrl} target="_blank" rel="noopener noreferrer" style={{textDecoration:"none"}}>
                <Typography variant="body2" color="text.secondary" style={{textAlign:"center", color:"white"}}>
                  Website URL
                </Typography>
                </a> */}
      </CardContent>
    </Card> 
    ))}
    </div>

    <div className='project-content' style={{marginTop: "18px"}}>
     {projectsData1.map((project, i)=>(
     <Card sx={{  }} className="project-slide">
      <CardContent className='project-card-content'>
        <Typography gutterBottom variant="h5" component="div">
          {project.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {project.technologies}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {project.year}
        </Typography>
                {/* <a href={project.githubUrl} style={{textDecoration:"none"}}>
                  <Typography variant="body2" color="text.secondary" style={{textAlign:"center", color:"white"}}>
                  GitHub URL
                </Typography>
                </a>
                <a href={project.webUrl} target="_blank" rel="noopener noreferrer" style={{textDecoration:"none"}}>
                <Typography variant="body2" color="text.secondary" style={{textAlign:"center", color:"white"}}>
                  Website URL
                </Typography>
                </a> */}
      </CardContent>
    </Card> 
    ))}
    </div>


    <div className='projects-heading2'>
        My Personal Projects
        </div>
      <div className='project-content'>
     {personalprojectsData.map((project, i)=>(
     <Card sx={{ }} className="project-slide">
      <CardContent className='project-card-content'>
        <Typography gutterBottom variant="h5" component="div">
          {project.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {project.technologies}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {project.year}
        </Typography>
                {/* <a href={project.githubUrl} style={{textDecoration:"none"}}>
                  <Typography variant="body2" color="text.secondary" style={{textAlign:"center", color:"white"}}>
                  GitHub URL
                </Typography>
                </a> */}
                <a href={project.webUrl} target="_blank" rel="noopener noreferrer" style={{textDecoration:"none"}}>
                <Typography variant="body2" color="text.secondary" style={{textAlign:"center", color:"white"}}>
                  Live demo
                </Typography>
                </a>
      </CardContent>
    </Card> 
    ))}
    </div>
    </div>  
    </div>
    
  )
}

export default Projects